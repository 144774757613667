import * as styles from "./SVGReviewsIOLogo.module.css";

import {
  ISVGProps,
  ISVGReviewsIOComponentProps,
} from "@models/component-props";

import React from "react";
import { SVGFromIconName } from "@components/svgs";
import { useSVGDimensions } from "@hooks";

/**
 * A reusable SVG component that renders the Reviews IO logo with the correct
 * dimensions and styling. The component takes a colour prop which is used to
 * apply a the correct colour to the logo. The component also takes a class name
 * as a prop which is applied to the SVG element.
 *
 * @param {ISVGReviewsIOComponentProps} props
 * @returns {React.ReactElement}
 */
const SVGReviewsIOLogo = ({
  colour,
  className,
  width,
  height,
}: ISVGReviewsIOComponentProps) => {
  const icon = "reviews-io-logo";

  // Set the default dimensions object for the SVG
  // This can also be found with the rest of the dimensions in constants/svgDimensions
  const defaultDimensions = {
    icon,
    dimensions: {
      width: 180.9504132231405,
      height: 29,
    },
    ratio: 6.239669421487603,
    viewBox: "0 0 755 121",
    "aria-label": "Reviews IO logo",
  };

  // Get the dimensions required for the icon
  const svgDimensions = useSVGDimensions({
    dimensionsProvided: { width, height },
    defaultDimensions: defaultDimensions!.dimensions,
    widthHeightRatio: defaultDimensions!.ratio,
  });

  // Set the SVG props
  const svgProps: ISVGProps = {
    className,
    width: svgDimensions.width,
    height: svgDimensions.height,
    viewBox: defaultDimensions.viewBox,
    "aria-label": defaultDimensions["aria-label"],
  };

  // The class name based on the colour provided
  // The reviews IO logo has some standard different options for colours
  // so the useSVGColour isn't used here
  const svgPropsClassName = `${styles.svgReviewsIOLogo} ${
    colour === "white"
      ? styles.svgWhite
      : colour === "black"
      ? styles.svgBlack
      : colour === "inverted"
      ? styles.svgInverted
      : ""
  } ${svgProps.className || ""}`;

  // Return the SVG code based on the icon and the class name
  return (
    <SVGFromIconName
      icon={icon}
      svgProps={{ ...svgProps, className: svgPropsClassName }}
    />
  );
};

export default SVGReviewsIOLogo;
